#booking {
  //padding-top: 0.618*64px;
  background-color: $synchron-grey-light;

  h4,
  h5 {
    color:          $synchron-grey-dark;
    text-transform: uppercase;
  }

  > .row {
    margin: 0;

    > form {
      padding: 0;
      margin:  0;

      > .row {
        margin: 12px 12px 0;

        &[data-initialize="pushpin"] {
          z-index:          1000;
          background-color: transparentize($synchron-white, 0.3);
          margin:           0;
          padding:          1rem;
          width:            100%;

          > .col {
            padding-left:  6px;
            padding-right: 6px;
          }
        }
      }

      > .inner {
        display: block;
        padding: 12px 6px 0;
        margin:  12px;

        > .well {
          display:          inline-block;
          width:            100%;
          background-color: $synchron-white;
          padding:          12px;

          > .col {
            &:last-child {
              > .row {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
