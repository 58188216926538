@import '../../../node_modules/materialize-css/sass/components/color';
@import 'colors';
@import 'constants';

$primary-color: $primary-color;
$secondary-color: $synchron-blue-light;

.btn-block {
  display: inline-block;
  width:   100%;
}

.waves-effect {
  // hack to make safari respect the padding box when clipping ripples
  //mask-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC');

  &.waves-blue .waves-ripple {
    background-color: transparentize($synchron-blue-dark, 0.3);
    background-color: transparentize($synchron-blue-dark, 0.3);
  }

  &.waves-blue-accent .waves-ripple {
    background-color: transparentize($synchron-blue-light, 0.3);
  }
}

table.highlight > tbody > tr:hover {
  background-color: $synchron-grey-light;
}

.synchron-blue-dark {
  background-color: $synchron-blue-dark !important;
}

.synchron-blue-light {
  background-color: $synchron-blue-light;
}

.synchron-blue-dark-text {
  color: $synchron-blue-dark;
}

.synchron-blue-light-text {
  color: $synchron-blue-light;
}

.btn,
.btn-floating {
  &.btn-primary {
    background-color: $synchron-blue-dark;

    &:hover {
      background-color: tint($synchron-blue-dark, 40%);
    }
  }

  &.btn-danger,
  &.btn-error {
    background-color: $synchron-red;
    color:            $synchron-grey-light;

    &:hover {
      background-color: tint($synchron-red, 40%);
    }
  }

  &.btn-success {
    background-color: $synchron-green;
    color:            $synchron-grey-dark;

    &:hover {
      background-color: tint($synchron-green, 40%);
    }
  }

  &.btn-warning {
    // scss-lint:disable PlaceholderInExtend
    @extend .orange;
    color: $synchron-grey-light;

    &:hover {
      background-color: tint($synchron-green, 40%);
    }
  }
}

// scss-lint:disable PlaceholderInExtend
@mixin alert($bg, $fg) {
  @extend .card-panel;
  @extend .z-depth-1;
  @extend #{$bg};
  @extend #{$fg};

  transition: $default-easing;

  // scss-lint:disable ImportantRule
  padding:    5px 10px !important;

  &.slide-in {
    margin-bottom: -2rem;
    opacity:       0;
    transform:     scaleY(0);
  }
}

.alert-error {
  @include alert (".red", ".white-text");
  // scss-lint:disable ImportantRule, ColorVariable
  background-color: #E52400 !important;
}

.alert-success {
  @include alert (".light-green.darken-4", ".white-text");
  // scss-lint:disable ImportantRule, ColorVariable
  background-color: #00A839 !important;
}

.alert-info {
  @include alert (".light-blue.darken-2", ".white-text");
  // scss-lint:disable ImportantRule
  background-color: $synchron-blue-light !important;
}

.alert-warning {
  @include alert (".orange", ".white-text");
}

.clockpicker-display {
  color: $synchron-white;

  .text-primary {
    color: $synchron-blue-dark;
  }
}

.clockpicker-tick.active,
.clockpicker-tick:hover {
  background-color: rgba(lighten($primary-color, 50%), 0.25);
}

// scss-lint:disable SelectorFormat
.picker__footer {
  .clockpicker-button {
    background-color: $synchron-blue-dark;
    color:            $synchron-white;

    &:active,
    &:focus {
      background-color: $synchron-blue-dark;
      color:            $synchron-blue-light;
    }
  }
}

.material-tooltip {
  background-color: $synchron-grey-dark;
}

.rating i {
  cursor:    pointer;
  color:     color('yellow', 'base');
  font-size: 2rem;
}

.clockpicker-svg {
  g {
    line {
      stroke: rgba(lighten($primary-color, 50%), 0.25);
    }

    .clockpicker-canvas-bg {
      fill: rgba(lighten($primary-color, 50%), 0.25);
    }

    .clockpicker-canvas-fg.active,
    .clockpicker-canvas-bearing {
      fill: rgba(lighten($primary-color, 20%), 0.5);
    }
  }
}

table.tablesorter {
  thead {
    td {
      cursor:  pointer;
      outline: none;

      &:hover {
        background-color: $synchron-grey-light;
      }

      &[aria-sort="descending"] div:after {
        content:   '\25BA';
        transform: rotate(90deg);
        float:     right;
      }

      &[aria-sort="ascending"] div:after {
        content:   '\25BA';
        transform: rotate(-90deg);
        float:     right;
      }
    }
  }
}

.modal {
  .modal-content {
    p {
      color: $synchron-grey-dark;
    }
  }
}

.pagination {
  .waves-effect {
    overflow:       inherit;
    vertical-align: inherit;
  }
}

.fix-select2 .select2-search--inline:first-child {
  margin-left: -5px;

  // scss-lint:disable ImportantRule
  .select2-search__field {
    margin-bottom: 0 !important;
    border-bottom: 0 !important;
  }
}

.select-wrapper {
  select {
    position: absolute;
  }
}

.clockpicker-am-pm-block {
  animation-duration:        0.3s;
  animation-timing-function: $default-easing-function;
  margin-bottom:             90px;
  margin-top:                -140px;
  pointer-events:            none;

  .timepicker-label {
    display:     block;
    padding-top: 10px;
    width:       100%;
    height:      100%;
    line-height: 50px;
    text-align:  center;
    font-weight: 400;
    color:       $synchron-blue-dark;
  }
}
