@import '../../../node_modules/materialize-css/sass/components/color';
@import '../../../node_modules/bourbon/app/assets/stylesheets/css3/linear-gradient';

#login,
#register,
#confirm,
#forgot-password,
#accept_agbs_dispotool,
#accept_agbs_kidito {
  width:          100vw;
  min-height:     100vh;
  position:       absolute;
  top:            0;
  left:           0;

  padding-top:    80px;
  padding-bottom: calc(2rem + (5 * 2 * 14px));
  box-sizing:     border-box;
  overflow-y:     auto;

  //noinspection CssInvalidFunction,CssUnknownTarget
  background:     linear-gradient(
                          transparentize(color("grey", "darken-4"), 0.3),
                          transparentize(color("grey", "darken-4"), 0.3)),
                  url("/images/login-bg-1.jpg");

  #company-wrapper,
  #company-name-wrapper,
  #license-type-wrapper {
    transition:       $default-easing;
    transform-origin: center top;

    &.slide-in {
      margin-top:    0;
      margin-bottom: -5.2rem;
      opacity:       0;
      transform:     scaleY(0);
    }
  }

  #dob-wrapper {
    transition:       $default-easing;
    transform-origin: center top;

    &.slide-in {
      margin-bottom: -6.3rem;
      opacity:       0;
      transform:     scaleY(0);
    }
  }

  #employment-type-wrapper {
    transition:       $default-easing;
    transform-origin: left center;

    &.slide-in {
      //margin-left: -100%;
      opacity:   0;
      transform: scaleX(0);
    }
  }
}
