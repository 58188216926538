@import 'colors';

#todo {
  padding-top: 0.618*64px;

  h3,
  h4,
  h5 {
    color:          $synchron-grey-dark;
    text-transform: uppercase;
  }

  .collection {
    .collection-item {
      padding-left: 20px;
      color:        $synchron-grey-dark;

      .title {
        font-weight: 400;
      }

      .secondary-content {
        //border:    1px solid $synchron-grey-dark;
        font-size: 12px;
        top:       12px;

        > form {
          display:    inline-block;
          margin-top: 4px;

          input[type=text] {
            color: $synchron-grey-dark;
          }
        }

        a,
        button {
          &:not(.btn-danger):not(.btn-success):not(btn-warning) {
            background-color: $synchron-blue-dark;
            color:            $synchron-white;

            &:hover {
              background-color: $synchron-blue-light;
            }
          }
        }
      }
    }
  }
}
