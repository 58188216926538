nav.breadcrumbs {
  height:           0.618*64px;
  line-height:      0.618*64px;
  position:         fixed;
  top:              64px;
  width:            1020px;
  z-index:          997;
  box-shadow:       none;
  color:            $synchron-blue-dark;
  background-color: $synchron-white;

  .col {
    padding: 0 0.75rem;

    .breadcrumb {
      font-size: 14px;
      color:     $synchron-blue-dark;

      &:last-child {
        color: $breadcrumb-last-color;
      }

      &:before {
        color: rgba($synchron-blue-dark, 0.7);
      }
    }
  }
}

@media only screen and (max-width: 1050px) {
  nav.breadcrumbs {
    width: 85%;
  }
}
