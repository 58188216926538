@import 'constants';
@import 'colors';

#master-layout {
  position:       relative;
  padding-bottom: 180px;
  min-height:     100vh;
}

.footer {
  background-color: $synchron-grey-dark;
  color:            $synchron-white;
  position:         absolute;
  bottom:           0;
  width:            100%;
  z-index:          1;
  height:           180px;

  .row {
    margin-bottom:  0;

    padding-top:    1rem;
    padding-bottom: 1rem;

    a {
      @include animated-underline($synchron-white, 1px);

      color:          $synchron-white;
      text-transform: uppercase;
      font-size:      14px;
      line-height:    2em;
    }

    p {
      font-size:  12px;
      margin-top: 0;

      &:first-child {
        margin-top: 7px;
      }
    }
  }

  .version-number {
    height: 151px;

    p {
      position: absolute;
      bottom: 0;
      right: 10px;

      font-size: 10px;
    }
  }
}

#register {
  + .footer {
    position: fixed;
  }
}
