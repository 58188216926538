@import 'colors';

#dashboard {
  margin-top: 28px;

  h5 {
    margin-top: 0;
  }

  .section {
    transition:          $default-easing;
    transition-duration: 0.45s;
    transform-origin:    top;

    //max-height: calc(100vh - 50px);
    opacity:             1;
    position:            absolute;
    top:                 145px;
    width:               1020px;

    &.hidden {
      opacity:   0;
      transform: scaleY(0.1) translateY(5vh);
      z-index:   -1;
    }

    .box-holder {
      .box {
        .profile-picture {
          position:        relative;
          width:           4rem;
          height:          4rem;
          border-radius:   50%;
          background:      $synchron-grey-dark no-repeat center center;
          background-size: cover;

          .placeholder,
          .actual {
            transition: $default-easing;

            display:    block;
            position:   absolute;
            top:        0;
            left:       0;
            width:      100%;
            height:     100%;
          }

          .placeholder {
            z-index:          1;
            background-color: $synchron-white;
            //noinspection CssUnknownTarget
            mask:             url("/images/image-placeholder.svg") no-repeat center center;
            mask-size:        50% 50%;
          }

          .actual {
            z-index:         2;
            background-size: cover;
            background:      transparent no-repeat center center;
            border-radius:   50%;
          }
        }
      }
    }
  }
}
