@import 'colors';

#profile-wrapper {
  background-color: $synchron-grey-light;
  min-height:       calc(100vh - 64px);

  > .row {
    min-height: calc(100vh - 64px);
    margin:     0;
  }
}

#profile-left,
#profile-right {
  padding:    0 0 12px;
  min-height: calc(100vh - 64px);

  > .container-fluid,
  > .container-fluid > form {
    margin: 0;
  }
}

#profile-left {
  background-color: $synchron-blue-light;
  color:            $synchron-white;

  /*&.child {
    background-color: color('blue-grey', 'base');

    a {
      @include animated-underline($synchron-white, 1px);

      color: $synchron-white;
    }
  }*/

  a {
    @include animated-underline($synchron-blue-dark, 1px);

    color:       $synchron-blue-dark;
    font-size:   14px;
    line-height: 1.25em;
    font-weight: 200;
  }

  .cell {
    display:       block;
    position:      relative;
    width:         100%;
    padding:       18px;
    border-bottom: 2px solid $synchron-white;

    > h5,
    > span,
    > strong {
      color:       $synchron-white;
      line-height: 1em;
      margin:      0;
      font-weight: 200;
    }

    > h5 {
      font-size:     24px;
      margin-bottom: 6px;
    }

    > span,
    > strong {
      font-size:    14px;
      padding-left: 54px;
    }

    strong {
      font-weight: 700;
    }

    .icon-left {
      position:  absolute;
      font-size: 36px;
      top:       50%;
      transform: translateY(-50%);
    }

    .profile-picture {
      position:        relative;
      width:           100%;
      border-radius:   50%;
      background:      $synchron-grey-dark no-repeat center center;
      background-size: cover;

      &:before {
        content: '';
        padding: 50% 0;
        display: inline-block;
      }

      .placeholder,
      .actual {
        transition: $default-easing;

        display:    block;
        position:   absolute;
        top:        0;
        left:       0;
        width:      100%;
        height:     100%;
      }

      .placeholder {
        z-index:          1;
        background-color: $synchron-white;
        //noinspection CssUnknownTarget
        mask:             url("/images/image-placeholder.svg") no-repeat center center;
        mask-size:        50% 50%;
      }

      .actual {
        z-index:         2;
        background-size: cover;
        background:      transparent no-repeat center center;
      }
    }

    .align-right {
      display:    inline-block;
      width:      100%;
      text-align: right;
    }
  }
}

#profile-right {
  > .container-fluid,
  > .container-fluid > form {
    padding: 0;
    margin:  0;

    > .row {
      margin: 12px 12px 0;

      &[data-initialize="pushpin"] {
        z-index:          1000;
        background-color: transparentize($synchron-white, 0.3);
        margin:           0;
        padding:          1rem;
        width:            100%;
      }

      > .col {
        padding-left:  6px;
        padding-right: 6px;

        &:not(.s12) {
          &:not(:first-child) {
            padding-left: 6px;
          }

          &:not(:last-child) {
            padding-right: 6px;
          }
        }

        > h1 {
          font-size:   30px;
          font-weight: 200;
          margin:      0;

          + a {
            @include animated-underline($synchron-grey-dark, 1px);

            font-size: 14px;
            color:     $synchron-grey-dark;
          }
        }

        > .well {
          display:          block;
          width:            100%;
          background-color: $synchron-white;
          padding:          12px;

          > h6 {
            color:          $synchron-blue-dark;
            font-weight:    700;
            line-height:    1em;
            margin:         0;
            padding-bottom: 0.2em;
            border-bottom:  1px solid transparentize($synchron-grey-dark, 0.7);

            a {
              @include animated-underline($synchron-blue-dark, 1px);

              margin-top: -1em;
              color:      $synchron-blue-dark;
            }

            span.accept-end {
              display:     block;
              margin-top:  -1em;
              width:       100%;
              text-align:  center;
              font-weight: 200;
            }
          }

          > table {
            margin-bottom: -5px;

            tbody,
            thead {
              tr {
                td,
                th {
                  padding-top:    5px;
                  padding-bottom: 5px;
                  font-size:      14px;
                  vertical-align: top;

                  strong {
                    color: $synchron-grey-dark;
                  }

                  p {
                    margin: 0;
                  }

                  &:first-child {
                    padding-left: 0;
                  }
                }
              }
            }

            thead {
              border-bottom: 1px solid $synchron-blue-dark;

              tr th {
                font-size:      18px;
                padding-bottom: 0;
                color:          $synchron-grey-dark;
              }
            }
          }

          > form {
            margin-top: 10px;
          }

          .vita {
            color: $synchron-grey-dark;
          }
        }

        #allow-anyways {
          transition:       $default-easing;
          transform-origin: center top;

          &.slide-in {
            opacity:   0;
            transform: scaleY(0);
            height:    0;
          }
        }
      }
    }
  }
}
