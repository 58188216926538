@import 'colors';

#production {
  padding-top: 0.618*64px;

  .production-list {
    .collection-item {
      padding-left: 20px;
      color:        $synchron-grey-dark;

      .title {
        font-weight: 400;
        display:     inline-block;
        width:       calc(100% - 300px);
        margin-top: 15px;
      }

      p {
        margin-left: 65px;
      }

      .secondary-content {
        border:    1px solid $synchron-grey-dark;
        font-size: 12px;
        top: 18px;
        right: 18px;

        &:hover {
          background-color: $synchron-blue-dark;
          color:            $synchron-white;
          border-color:     $synchron-blue-dark;
        }
      }
    }
  }

  em {
    color: $synchron-grey-dark;
  }

  > .row {
    margin-bottom: 30px;

    .row {
      margin-bottom: 0;
    }
  }

  .details {
    td {
      padding: 2px 5px;
      color:   $synchron-grey-dark;

      &:nth-child(2) {
        font-weight: 400;
      }
    }
  }

  .roles {
    tr {
      td {
        position:       relative;
        vertical-align: super;

        .links {
          transition: $default-easing;

          opacity:    0;
          display:    block;
          margin-top: -2px;

          .separator {
            color: $synchron-grey-dark;
          }

          a {
            @include animated-underline($synchron-link-color, 1px);
            font-size: 12px;

            &.delete {
              @include animated-underline($synchron-red, 1px);
              color: $synchron-red;
            }
          }
        }
      }

      &:hover {
        .links {
          opacity: 1;
        }
      }
    }

    tbody {
      td {
        padding: 5px 5px 0;
      }
    }
  }

  table {
    color: $synchron-grey-dark;
  }

  .collapsible {
    border:     0;
    box-shadow: none;

    .collapsible-header {
      border:         0;
      position:       relative;
      font-size:      1.64rem;
      padding-left:   2rem;
      color:          $synchron-grey-dark;
      text-transform: uppercase;
      font-weight:    400;

      &:before {
        transition:  $default-easing;

        position:    absolute;
        left:        0;
        line-height: 3rem;
        content:     '\25BA';
      }

      &.active {
        &:before {
          transform: rotate(90deg);
        }
      }
    }
  }

  #block-template {
    display: none;
  }

  #add-block {
    background-color: $synchron-white;
    color:            $synchron-grey-dark;
    border:           1px solid $synchron-grey-dark;

    span {
      font-size:      12px;
      line-height:    inherit;
      display:        inline-block;
      padding-bottom: 6px;
      vertical-align: middle;
    }

    &:hover {
      background-color: $synchron-blue-dark;
      color:            $synchron-white;
      border-color:     $synchron-blue-dark;
    }
  }
}

#role-user-search {
  padding-top: 0.618*64px;

  .collection-item {
    padding-left: 94px;

    .profile-picture {
      position:        absolute;
      left:            15px;
      width:           64px;
      height:          64px;
      border-radius:   50%;
      background:      $synchron-grey-dark no-repeat center center;
      background-size: cover;

      &:before {
        content: '';
        padding: 50% 0;
        display: inline-block;
      }

      .placeholder,
      .actual {
        transition: $default-easing;

        display:    block;
        position:   absolute;
        top:        0;
        left:       0;
        width:      100%;
        height:     100%;
      }

      .placeholder {
        z-index:          1;
        background-color: $synchron-white;
        //noinspection CssUnknownTarget
        mask:             url("/images/image-placeholder.svg") no-repeat center center;
        mask-size:        50% 50%;
      }

      .actual {
        z-index:         2;
        background-size: cover;
        background:      transparent no-repeat center center;
      }
    }

    .secondary-content {
      border:    1px solid $synchron-grey-dark;
      font-size: 12px;

      &:hover {
        background-color: $synchron-blue-dark;
        color:            $synchron-white;
        border-color:     $synchron-blue-dark;
      }
    }
  }
}

div {
  &.production-type-image {
    width: 50px;
    height: 50px;
    display: inline-block;
    vertical-align: -18px;
    margin-right: 10px;

    &.small {
      width: 30px;
      height: 30px;
      vertical-align: -9px;
    }

    &.production-type-synchron {
      //noinspection CssUnknownTarget
      content: url('/images/icn-serie-synchron-kidito.png');
    }
    &.production-type-voiceover {
      //noinspection CssUnknownTarget
      content: url('/images/icn-serie-voiceover-blau-kidito.png');
    }
    &.production-type-videogame {
      //noinspection CssUnknownTarget
      content: url('/images/icn-serie-videogame-kidito.png');
    }
    &.production-type-commercial {
      //noinspection CssUnknownTarget
      content: url('/images/icn-serie-commercial-kidito.png');
    }
    &.production-type-audiobook {
      //noinspection CssUnknownTarget
      content: url('/images/icn-serie-audiobook-kidito.png');
    }
    &.production-type-audiodrama {
      //noinspection CssUnknownTarget
      content: url('/images/icn-serie-audiodrama-blau-kidito.png');
    }
  }
}
