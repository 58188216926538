//$primary-color: transparentize(color("grey", "darken-4"), 0.1);
$synchron-blue-dark: #006A8F !default;
$synchron-blue-light: #90C8E8 !default;
$synchron-white: #FFF !default;
$synchron-black: #000 !default;
$synchron-red: #EA6878 !default;
$synchron-green: #B1D398 !default;
$synchron-orange: #F4A36C !default;
$synchron-grey-light: #EEE !default;
$synchron-grey-dark: #787878 !default;
$synchron-link-color: #039BE5 !default;
$primary-color: transparentize($synchron-blue-dark, 0.1);
$accent-color: transparentize($synchron-blue-light, 0.1);

$navbar-background: $primary-color !default;
$navbar-link-active-color: color("grey", "darken-4") !default;
$navbar-link-color: $synchron-white !default;
$navbar-link-active: map-get($shades, "white") !default;
$navbar-link-hover: transparentize($navbar-link-active, 0.15);

$breadcrumb-last-color: $synchron-blue-light !default;

$text-darker: #333E48;

$background-fallback: #333;

$tint-active: $synchron-blue-dark !default;
$tint-active-contrast: color("grey", "darken-4") !default;
$tint-accent: $synchron-blue-light !default;
$tint-inactive: $synchron-grey-light !default;

$tint-active-inverse: $synchron-blue-dark !default;
$tint-active-contrast-inverse: color("grey", "darken-4") !default;
$tint-accent-inverse: $synchron-blue-dark !default;
$tint-inactive-inverse: $synchron-grey-dark !default;
//$tint-inactive: #9E9E9E;

$boxes-hover-background: $synchron-blue-dark !default;
$boxes-hover-text: $synchron-white !default;

$option-hover-background: #eee;
$option-selected-background: #e1e1e1;
$option-disabled-foreground: rgba(0, 0, 0, 0.3);

$button-danger-light: #ed7d8b;
