// scss-lint:disable QualifyingElement
@import 'colors';

.tint,
.tint-inverse {
  .form-row {
    &.modal-row {
      margin-bottom: 0;
    }
  }
}

form.tint {
  color: $tint-inactive;

  .input-field input[type=text],
  .input-field input[type=password],
  .input-field input[type=number]:focus,
  .input-field input[type=email],
  .input-field textarea {
    border-bottom-color: $tint-inactive;
    color:               $tint-inactive;

    + label,
    + i {
      color: $tint-inactive;
    }
  }

  .select-wrapper {
    .caret,
    + label {
      color: $tint-inactive;
    }
  }

  input[type=radio],
  input[type=checkbox] {
    &:not(:checked) {
      + label {
        color: $tint-inactive;
      }
    }

    &:checked {
      + label {
        color: $tint-accent;
      }
    }
  }

  input[type=radio].with-gap:not(:checked) + label {
    &:before,
    &:after {
      border-color: $tint-inactive;
    }
  }

  input[type=radio].with-gap:checked + label {
    &:before,
    &:after {
      border-color: $tint-accent;
    }

    &:after {
      border:           0;
      background-color: $tint-accent;
    }
  }

  input[type=checkbox] {
    &:not(:checked) + label:before {
      border-color: $tint-inactive;
    }

    &:checked + label:before {
      border-bottom: 2px solid $tint-accent;
      border-right:  2px solid $tint-accent;
    }
  }

  .input-field input[type=text]:focus,
  .input-field input[type=password]:focus,
  .input-field input[type=email]:focus,
  .input-field input[type=number]:focus,
  .input-field textarea:focus {
    border-bottom: 1px solid $tint-accent;
    box-shadow:    0 1px 0 0 $tint-accent;

    + label,
    + i {
      color: $tint-accent;
    }
  }

  a:not(.btn):not(.btn-floating) {
    @include animated-underline($tint-accent, 1px);

    position: relative;
    color:    $tint-accent;
  }

  button {
    transition:       background-color 0.3s $default-easing-function;

    box-sizing:       border-box;
    line-height:      100%;
    background-color: transparent;
    border:           2px solid $tint-inactive;

    &:hover {
      background-color: $tint-active;
      border-width:     0;
      color:            $tint-inactive;
    }
  }

  .fixed-label {
    position: relative;

    > label {
      position: absolute;
      top:      0;
      left:     0.75rem;
    }

    > :not(label) {
      margin-top: 1.75rem;
    }
  }

  .select2 {
    // scss-lint:disable ImportantRule
    width:         100% !important; // needed here to take precedence over inline width
    margin-bottom: 20px;

    .select2-selection {
      height:           3rem;
      border:           0;
      border-bottom:    1px solid $tint-inactive;
      border-radius:    0;
      background-color: transparent;
      outline:          none;

      &:hover,
      &:focus {
        border-bottom-color: $tint-accent;
        box-shadow:          0 1px 0 0 $tint-accent;
      }
    }

    // scss-lint:disable SelectorFormat
    .select2-selection__rendered {
      display:     block;
      height:      3rem;
      line-height: 3rem;
      color:       $synchron-white;
      padding:     0;

      .select2-selection__choice {
        background-color: $tint-accent;
        border-color:     $tint-accent;
        border-radius:    1rem;
        padding:          0 12px;
        color:            $synchron-white;

        .select2-selection__choice__remove {
          color:          $synchron-white;
          padding-bottom: 4px;
          margin-bottom:  -2px;
          margin-right:   4px;
        }
      }

      .select2-search__field {
        height:        2rem;
        margin:        0 0 5px;
        border-bottom: 1px solid $tint-inactive;
        color:         $tint-inactive;

        &:focus {
          border-bottom: 1px solid $tint-accent;
          box-shadow:    0 1px 0 0 $tint-accent;
        }
      }
    }

    .select2-selection__arrow {
      height:      3rem;
      line-height: 3rem;

      b {
        border-color: $synchron-white transparent transparent;
      }
    }
  }
}

form.tint-inverse {
  color: $tint-inactive-inverse;

  .input-field input[type=text],
  .input-field input[type=password],
  .input-field input[type=email],
  .input-field input[type=number]:focus,
  .input-field textarea {
    &:not([disabled]) {
      border-bottom: 1px solid $tint-inactive-inverse;
      color:         $tint-inactive-inverse;

      + label,
      + i,
      i {
        color: $tint-inactive-inverse;
      }
    }
  }

  input[type=radio],
  input[type=checkbox] {
    &:not(:checked) {
      + label {
        color: $tint-inactive-inverse;
      }
    }

    &:checked {
      + label {
        color: $tint-accent-inverse;
      }
    }
  }

  input[type=radio].with-gap:not(:checked) + label {
    &:before,
    &:after {
      border-color: $tint-inactive-inverse;
    }
  }

  input[type=radio].with-gap:checked + label {
    &:before,
    &:after {
      border-color: $tint-accent-inverse;
    }

    &:after {
      border:           0;
      background-color: $tint-accent-inverse;
    }
  }

  input[type=checkbox] {
    &:not(:checked) + label:before {
      border-color: $tint-inactive-inverse;
    }

    &:checked + label:before {
      border-bottom: 2px solid $tint-accent-inverse;
      border-right:  2px solid $tint-accent-inverse;
    }
  }

  .input-field input[type=text]:focus,
  .input-field input[type=password]:focus,
  .input-field input[type=email]:focus,
  .input-field input[type=number]:focus,
  .input-field textarea:focus {
    border-bottom: 1px solid $tint-accent-inverse;
    box-shadow:    0 1px 0 0 $tint-accent-inverse;

    + label,
    + i {
      color: $tint-accent-inverse;
    }
  }

  a:not(.btn):not(.btn-floating) {
    @include animated-underline($tint-accent-inverse, 1px);

    position: relative;
    color:    $tint-accent-inverse;
  }

  button:not(.btn-danger):not(.btn-success),
  .btn:not(.btn-danger):not(.btn-success) {
    transition:       background-color 0.3s $default-easing-function;

    box-sizing:       border-box;
    height:           36px;
    line-height:      36px;
    background-color: $tint-accent-inverse;
    color:            $synchron-white;

    &:hover {
      color:            $synchron-blue-light;
      background-color: $tint-active-inverse;
    }
  }

  .btn-floating:not(.btn-danger):not(.btn-success) {
    transition:       background-color 0.3s $default-easing-function;
    background-color: $tint-accent-inverse;
    color:            $synchron-white;

    &:hover {
      color:            $synchron-blue-light;
      background-color: $tint-active-inverse;
    }
  }

  input[type=range]::-webkit-slider-thumb {
    background-color: $tint-active-inverse;
  }

  .thumb {
    background-color: $tint-active-inverse;
  }

  // scss-lint:disable SelectorFormat
  .noUi-handle {
    background-color: $tint-active-inverse;

    > .range-label {
      background-color: $tint-active-inverse;
    }
  }

  // scss-lint:disable SelectorFormat
  .noUi-connect {
    background-color: $tint-accent;
  }

  // scss-lint:disable SelectorFormat
  .picker__footer {
    button {
      &:hover {
        border: 2px solid $tint-active-inverse;
      }
    }
  }

  .switch label input[type=checkbox]:checked + .lever {
    background-color: $synchron-blue-light;

    &:after {
      background-color: $synchron-blue-dark;
    }
  }

  select.error ~ .select-dropdown {
    border-bottom: 1px solid $synchron-red;
  }

  .dropdown-content li {
    > a,
    > span {
      color: $tint-active-inverse;
    }

    &.disabled {
      > a,
      > span {
        color: $synchron-grey-dark;
      }
    }
  }

  .fixed-label {
    position: relative;

    > label {
      position: absolute;
      top:      0;
      left:     0.75rem;
    }

    > :not(label) {
      margin-top: 1.75rem;
    }

    &.input-field {
      > label {
        font-size: 12px;
        top:       -14px;
      }

      > :not(label) {
        margin-top: calc(1.75rem - 15px);

        &.select2 {
          margin-top: 0;
        }
      }
    }
  }

  .select2 {
    // scss-lint:disable ImportantRule
    width:         100% !important; // needed here to take precedence over inline width
    margin-bottom: 20px;

    .select2-selection {
      border:           0;
      border-bottom:    1px solid $tint-inactive-inverse;
      border-radius:    0;
      background-color: transparent;
      outline:          none;
      box-sizing:       content-box;

      &:hover,
      &:focus {
        border-bottom-color: $tint-active-inverse;

        &:focus {
          box-shadow: 0 1px 0 0 $tint-active-inverse;
        }
      }

      &:not(.select2-selection--multiple) {
        height: 3rem;

        // scss-lint:disable SelectorFormat
        .select2-selection__rendered {
          display:     block;
          height:      3rem;
          line-height: 3rem;
          padding:     0;

          .select2-selection__choice {
            background-color: $tint-accent-inverse;
            border-color:     $tint-accent-inverse;
            border-radius:    1rem;
            padding:          0 12px;
            color:            $synchron-black;

            .select2-selection__choice__remove {
              color:          $synchron-black;
              padding-bottom: 4px;
              margin-bottom:  -2px;
              margin-right:   4px;
            }
          }

          .select2-search__field {
            height:        2rem;
            margin:        0 0 5px;
            border-bottom: 1px solid $tint-inactive-inverse;
            color:         $tint-inactive-inverse;

            &:focus {
              border-bottom: 1px solid $tint-accent-inverse;
              box-shadow:    0 1px 0 0 $tint-accent-inverse;
            }
          }
        }
      }

      &.select2-selection--multiple {
        padding-top: 10px;

        // scss-lint:disable SelectorFormat
        .select2-selection__rendered {
          display: block;
          //height:      3rem;
          //line-height: 3rem;
          //padding:     0;

          .select2-selection__choice {
            background-color: $tint-accent-inverse;
            border-color:     $tint-accent-inverse;
            border-radius:    1rem;
            padding:          0 12px;
            color:            $synchron-white;

            .select2-selection__choice__remove {
              color:          $synchron-white;
              padding-bottom: 4px;
              margin-bottom:  -2px;
              margin-right:   4px;
            }
          }

          .select2-search__field {
            height:        2rem;
            margin:        0 0 5px;
            border-bottom: 1px solid $tint-inactive-inverse;
            color:         $tint-inactive-inverse;

            &:focus {
              border-bottom: 1px solid $tint-accent-inverse;
              box-shadow:    0 1px 0 0 $tint-accent-inverse;
            }
          }
        }
      }
    }

    .select2-selection__arrow {
      height:      3rem;
      line-height: 3rem;

      b {
        border-color: $synchron-black transparent transparent;
      }
    }

    &.select2-container--disabled {
      .select2-selection--single {
        border-bottom: 1px dotted rgba(0, 0, 0, 0.26);

        &:focus {
          box-shadow: none;
        }

        .select2-selection__rendered {
          color: rgba(0, 0, 0, 0.26);
        }

        .select2-selection__arrow {
          b {
            border-color: rgba(0, 0, 0, 0.26) transparent transparent;
          }
        }
      }
    }
  }
}

a.tint {
  // scss-lint:disable ImportantRule
  color: $tint-active !important;
}

.select2-container--open {
  //box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  will-change: top, left;

  .select2-dropdown {
    // scss-lint:disable PlaceholderInExtend
    @extend .z-depth-1;

    transition:       transform 0.15s $default-easing-function, opacity 0.3s $default-easing-function;

    border:           0;
    border-radius:    0;
    transform:        scaleY(0);
    transform-origin: top;
    opacity:          0;

    &:not(.tokens) {
      &.select2-dropdown--below {
        margin-top: -3rem;
      }

      &.select2-dropdown--above {
        transform-origin: bottom;
      }
    }

    &.opening {
      opacity:   1;
      transform: scaleY(1);
    }

    // scss-lint:disable SelectorFormat
    .select2-search__field {
      border-top:    0;
      border-left:   0;
      border-right:  0;
      margin-bottom: 0;

      &:focus {
        border-bottom: 1px solid $tint-active;
        box-shadow:    0 1px 0 0 $tint-active;
      }
    }

    .select2-results__options {
      .select2-results__group {
        font-weight: normal;
        color:       $background-fallback;
        height:      40px;
        line-height: 1.5rem;
        padding:     9px 16px;
      }

      &[aria-multiselectable=true] {
        > .select2-results__option {
          min-height:  50px;
          line-height: 1.5rem;
          padding:     14px 16px;
          color:       $tint-active;

          //&.select2-results__option--highlighted:not(:hover):not([aria-selected=true]) {
          //  background-color: transparent;
          //}

          &:hover,
          &.select2-results__option--highlighted {
            background-color: $option-hover-background;
          }

          &[aria-selected=true] {
            background-color: $option-selected-background;
          }

          &[aria-disabled=true] {
            color: $option-disabled-foreground;
          }
        }
      }

      &:not([aria-multiselectable=true]) {
        > .select2-results__option {
          min-height:  50px;
          line-height: 1.5rem;
          padding:     14px 16px;
          color:       $tint-active;

          //&.select2-results__option--highlighted:not(:hover):not([aria-selected=true]) {
          //  background-color: transparent;
          //}

          &:hover,
          &.select2-results__option--highlighted {
            background-color: $option-hover-background;
          }

          &[aria-selected=true] {
            background-color: $option-selected-background;
          }

          &[aria-disabled=true] {
            color: $option-disabled-foreground;
          }
        }
      }
    }
  }
}
