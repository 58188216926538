@import '../../../node_modules/materialize-css/sass/components/color';
@import '../../../node_modules/bourbon/app/assets/stylesheets/css3/linear-gradient';
@import "colors";

#activate {
  width:          100vw;
  min-height:     100vh;
  position:       absolute;
  top:            0;
  left:           0;

  padding-top:    80px;
  padding-bottom: calc(2rem + (5 * 2 * 14px));
  box-sizing:     border-box;
  overflow-y:     auto;

  //noinspection CssInvalidFunction,CssUnknownTarget
  background:     linear-gradient(
                  transparentize(color("grey", "darken-4"), 0.3),
                  transparentize(color("grey", "darken-4"), 0.3)),
  url("/images/login-bg-1.jpg");

  #button_div {
    margin-top: 30px;
  }

  p {
    color: $tint-inactive;
  }

  table, th, td {
    padding: 0px;
    vertical-align: top;
    color: $tint-inactive;
  }

  #button_big {
    text-align: center;
    font-size: x-large;
    min-height: 45px;
    padding-top: 4px;

    i, .mdi {
      font-size: x-large;
    }
  }

  #button_small {
    margin-top: 5px;
  }

  #explanation_div {
    margin-top: 50px;
    min-height: 80px;
  }

  #explanation {
    min-height: 80px;
    resize: none;
    white-space: pre-wrap;
  }

  #radio_div {
    margin-bottom: 30px;

    div {
      margin-bottom: 20px;
    }
  }
}