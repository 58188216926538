$bourbon: "../../../node_modules/bourbon/app/assets/stylesheets";

$default-easing-function: cubic-bezier(0.4, 0, 0.2, 1);
$default-easing: all 0.3s $default-easing-function;

@mixin animated-underline($color, $height) {
  position: relative;
  display:  inline-block;

  &:before {
    transition:       $default-easing;
    content:          "";
    position:         absolute;
    width:            100%;
    height:           $height;
    bottom:           0;
    left:             0;
    background-color: $color;
    visibility:       hidden;
    opacity:          0;
  }

  &:hover {
    &:before {
      visibility: visible;
      opacity:    1;
    }
  }
}
