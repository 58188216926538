#calendar {
  padding-top: 0.618*64px;

  h4,
  h5 {
    color:          $synchron-grey-dark;
    text-transform: uppercase;
  }

  .fc-toolbar {
    margin-bottom: 0;

    h2 {
      font-size:   1.5em;
      line-height: 1.5;
    }
  }

  .fc-today {
    background-color: tint($synchron-blue-light, 80%);
  }

  .fc-event {
    //background-color: $synchron-blue-dark;
    //border-color:     $synchron-blue-dark;
  }

  .fc-body td:not(.fc-axis) {
    padding: 0 !important;
  }
}

.calendar-tooltip {
  .qtip-titlebar {
    padding:          4px 6px 0;
    line-height:      1.4rem;
    font-size:        1rem;
    background-color: $synchron-white;
  }

  .qtip-content {
    padding: 4px 6px;

    a {
      display:       block;
      padding:       0 4px;
      line-height:   1.4rem;
      font-size:     1rem;
      color:         $synchron-white;
      margin-bottom: 1px;
    }

    table tbody tr td {
      color: $synchron-white;

      &:first-child {
        width:          1%;
        white-space:    nowrap;
        vertical-align: top;
      }
    }
  }
}

.production-calender-box {
  display:       block;
  padding:       2px 4px;
  color:         $synchron-white;
  border-radius: 2px;
}

#blocking-times {
  padding-top: 0.618*64px;

  .collection {
    .collection-item {
      p {
        margin: 0;
        color:  rgba(0, 0, 0, 0.7);
      }
    }
  }
}
