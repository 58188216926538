#search-wrapper {
  .search-simple {
    .collection-item {
      .profile-picture {
        position:        absolute;
        left:            15px;
        width:           64px;
        height:          64px;
        border-radius:   50%;
        background:      $synchron-grey-dark no-repeat center center;
        background-size: cover;

        &:before {
          content: '';
          padding: 50% 0;
          display: inline-block;
        }

        .placeholder,
        .actual {
          transition: $default-easing;

          display:    block;
          position:   absolute;
          top:        0;
          left:       0;
          width:      100%;
          height:     100%;
        }

        .placeholder {
          z-index:          1;
          background-color: $synchron-white;
          //noinspection CssUnknownTarget
          mask:             url("/images/image-placeholder.svg") no-repeat center center;
          mask-size:        50% 50%;
        }

        .actual {
          z-index:         2;
          background-size: cover;
          background:      transparent no-repeat center center;
        }

        .ha-loader,
        .ha-player {
          display:  block;
          opacity:  0;
          height:   64px;
          width:    64px;
          position: absolute;
          top:      0;
        }

        .ha-loader {
          transition:       $default-easing;

          background-color: rgba($synchron-blue-dark, 0.9);
          color:            $synchron-white;
          z-index:          2;
          text-align:       center;

          &:hover {
            opacity: 1;
          }

          .wrapper {
            display:    inline-block;
            position:   relative;
            width:      18px;
            height:     18px;
            margin-top: 23px;

            span,
            i {
              transition: $default-easing;

              position:   absolute;
              left:       0;
              top:        0;
              font-size:  18px;
              width:      18px;
              height:     18px;
            }
          }
        }
      }

      .title {
        color:       $synchron-blue-dark;
        font-size:   2rem;
        display:     block;
        position:    absolute;
        top:         0;
        left:        94px;
        height:      84px;
        line-height: 84px;
      }

      .secondary-content {
        font-size:  1.5rem;
        text-align: right;

        .btn {
          border:    1px solid $synchron-grey-dark;
          font-size: 12px;

          &:hover {
            background-color: $synchron-blue-dark;
            color:            $synchron-white;
            border-color:     $synchron-blue-dark;
          }
        }
      }
    }
  }
}
