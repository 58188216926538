@import 'constants';
@import 'colors';

nav:not(.breadcrumbs) {
  z-index:          998;
  position:         fixed;
  top:              0;
  background-color: $navbar-background;

  .left,
  .right {
    .brand-logo {
      display:     block;
      position:    relative;
      padding:     0;
      font-weight: 100;
      height:      100%;

      img {
        height:         64px;
        padding-top:    2px;
        padding-bottom: 2px;
      }
    }
  }

  ul {
    li {
      //margin-top: -1px;
      box-sizing: border-box;

      &.active {
        a {
          background-color: $navbar-link-active;
          color:            $navbar-link-active-color;
        }
      }

      a {
        @include animated-underline($navbar-link-active, 1px);
        transition:             $default-easing;

        height:                 64px;
        font-weight:            200;
        text-transform:         uppercase;
        -webkit-font-smoothing: antialiased;
        color:                  $navbar-link-color;

        i.mdi {
          display:   inline-block;
          font-size: 1rem;
        }

        .badge {
          background-color: $synchron-red;
          color:            $synchron-white;
          border-radius:    50%;
          min-width:        22px;
        }

        &:hover {
          //background-color: $navbar-link-hover;
          //color:            $navbar-link-color;
        }
      }
    }
  }
}
