@import '../../../node_modules/bourbon/app/assets/stylesheets/bourbon';
@import 'materialize_variable_overrides';
@import '../../../node_modules/materialize-css/sass/materialize';
//@import '../../../node_modules/materialize-clockpicker/src/sass/clockpicker';
@import 'navbar';
@import 'footer';
@import 'breadcrumbs';
@import 'boxes';
@import 'tint';
@import 'login';
@import 'profile';
@import 'search';
@import 'dashboard';
@import 'production';
@import 'prebooking';
@import 'booking';
@import 'todo';
@import 'calendar';
@import 'credits';
@import "activate";

body {
  font-family:            "Helvetica Neue", Roboto, Helvetica, Arial, sans-serif;
  font-weight:            300;
  -webkit-font-smoothing: antialiased;

  padding-top:            64px;
}

.container {
  width:        1020px;
  max-width:    1020px;
  margin-left:  auto;
  margin-right: auto;
}

.container-fluid {
  margin: 0.75rem;

  > .row {
    margin: {
      left:  0.75rem;
      right: 0.75rem;
    }
  }
}

@media only screen and (max-width: 1050px) {
  .container {
    width: 85%;
  }
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h4,
h5 {
  color:          $synchron-grey-dark;
  text-transform: uppercase;
  margin-top:     1rem;
  margin-bottom:  1rem;
}

table:not(.picker__table) {
  > thead {
    > tr {
      > th {
        font-size:      18px;
        padding-bottom: 0;
        color:          $synchron-grey-dark;
      }
    }
  }

  tbody {
    td:not(.fc-day-top) {
      color: $synchron-grey-dark;
    }
  }

  &:not(.no-transform) {
    tbody {
      td:not(.fc-widget-content):not(.fc-event-container) {
        padding: 2px 5px;
      }
    }
  }

  &:not(.no-highlight-data) {
    tbody {
      td {
        &:nth-child(2) {
          font-weight: 400;
        }
      }
    }
  }
}
