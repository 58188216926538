@import "micromodal";

#prebooking, #prebooking_modal {

  padding-top: 0.618*64px;

  h4,
  h5 {
    color:          $synchron-grey-dark;
    text-transform: uppercase;
  }

  .subtitle {
    color: $synchron-blue-dark;

    a {
      color: $synchron-blue-dark;
    }
  }

  .progress-wrapper {
    width:       100%;
    height:      1.5rem;
    border:      1px solid $synchron-grey-light;
    color:       $synchron-white;
    font-size:   0;
    white-space: nowrap;

    > div {
      display:     inline-block;
      height:      100%;
      font-size:   1.2rem;
      line-height: 1.5rem;
      text-align:  center;
      cursor:      pointer;

      &.answered {
        background-color: $synchron-green;
      }

      &.read {
        @extend .orange;
      }
    }
  }

  #block-template {
    display: none;
  }

  #add-block {
    background-color: $synchron-white;
    color:            $synchron-grey-dark;
    border:           1px solid $synchron-grey-dark;

    span {
      font-size:      12px;
      line-height:    inherit;
      display:        inline-block;
      padding-bottom: 6px;
      vertical-align: middle;
    }

    &:hover {
      background-color: $synchron-blue-dark;
      color:            $synchron-white;
      border-color:     $synchron-blue-dark;
    }
  }

  #existing-prebooking-template {
    display: none;
  }

  #additional-prebooking-template {
    display: none;
  }

  #overlap_pb_day_template {
    display: none;
  }

  #add-additional-prebooking {
    background-color: $synchron-white;
    color:            $synchron-grey-dark;
    border:           1px solid $synchron-grey-dark;

    span {
      font-size:      12px;
      line-height:    inherit;
      display:        inline-block;
      padding-bottom: 6px;
      vertical-align: middle;
    }

    &:hover {
      background-color: $synchron-blue-dark;
      color:            $synchron-white;
      border-color:     $synchron-blue-dark;
    }
  }

  #modal-div {
    z-index: 10;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.5);
    width: 2000px;
    height: 2000px;
  }

  #block-template_modal {
    display: none;
  }

  #info_text_div {
    min-height: 100px;
  }

  #info_text_area {
    min-height: 100px;
    resize: none;
    white-space: pre-wrap;
  }

  #info_text_info {
    font-size: small;
  }

  #info_text_div_modal {
    min-height: 50px;
  }

  #info_text_area_modal {
    min-height: 50px;
    resize: none;
  }

  #info_text_info_modal {
    font-size: small;
  }

  .input-field label {
    pointer-events: auto;
  }

  #existing-prebooking-info {
    color: #787878;
  }
}
