@import 'colors';

.box-holder {
  width:    100%;
  position: relative;

  .box {
    transition:    $default-easing;

    display:       inline-block;
    width:         calc((100% - 12px) * 0.25);
    position:      relative;
    margin-top:    44px;
    border-radius: 5px;

    .remove-btn {
      position:    absolute;
      opacity:     0;
      width:       22px;
      height:      22px;
      font-size:   22px;
      line-height: 22px;
      right:       -10px;
      top:         -10px;
      z-index:     10;

      i {
        display:          block;
        height:           22px;
        width:            22px;
        color:            $synchron-red;
        background-color: $synchron-white;
        border-radius:    50%;
      }
    }

    &:before {
      content:     "";
      display:     block;
      padding-top: 100%;
    }

    &:hover {
      background-color: $boxes-hover-background;

      .content {
        color: $boxes-hover-text;

        hr {
          color:            $boxes-hover-text;
          background-color: $boxes-hover-text;
        }
      }

      .remove-btn {
        opacity: 1;
      }
    }

    &:nth-child(3n + 1),
    &:nth-child(3n + 2) {
      margin-right: 12.5%;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }

    .content {
      position:  absolute;
      top:       0;
      left:      0;
      bottom:    0;
      right:     0;
      padding:   12px;
      font-size: 12px;
      color:     $synchron-grey-dark;

      .title {
        display:            block;
        display:            -webkit-box;
        width:              100%;
        height:             3em;
        font-size:          14px;
        font-weight:        400;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow:      ellipsis;
        overflow:           hidden;
      }

      hr {
        height:           1px;
        border:           0;
        color:            $synchron-blue-dark;
        background-color: $synchron-blue-dark;
      }

      .progress-wrapper {
        width:     100%;
        height:    1rem;
        border:    1px solid $synchron-grey-light;
        font-size: 0;

        > div {
          display: inline-block;
          height:  100%;

          &.answered {
            background-color: $synchron-green;
          }

          &.read {
            @extend .orange;
          }
        }
      }

      .add {
        width:         100%;
        height:        20px;
        font-size:     20px;
        line-height:   20px;
        text-align:    center;
        margin-top:    16px;
        margin-bottom: 10px;

        i {
          background-color: $synchron-blue-dark;
          color:            $synchron-white;
          width:            20px;
          height:           20px;
          font-size:        20px;
          line-height:      20px;
          border-radius:    5px;
        }
      }

      .type-icon {
        position:        absolute;
        bottom:          12px;
        left:            12px;
        height:          30px;
        width:           30px;
        background-size: contain;

        &.series {
          //noinspection CssUnknownTarget
          background-image: url('/images/series.png');
        }

        &.no-series {
          //noinspection CssUnknownTarget
          background-image: url('/images/non_series.png');
        }
      }

      .new-items {
        position:         absolute;
        bottom:           12px;
        right:            12px;
        background-color: $synchron-red;
        color:            $synchron-white;
        height:           30px;
        width:            30px;
        line-height:      30px;
        font-size:        14px;
        text-align:       center;
        border-radius:    50%;
        font-weight:      400;
      }

      table {
        tbody {
          tr {
            td {
              padding-top:    3px;
              padding-bottom: 3px;
            }

            &:first-child {
              td {
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .profile-picture {
      position:        relative;
      width:           4rem;
      height:          4rem;
      border-radius:   50%;
      background:      $synchron-grey-dark no-repeat center center;
      background-size: cover;

      .placeholder,
      .actual {
        transition: $default-easing;

        display:    block;
        position:   absolute;
        top:        0;
        left:       0;
        width:      100%;
        height:     100%;
      }

      .placeholder {
        z-index:          1;
        background-color: $synchron-white;
        //noinspection CssUnknownTarget
        mask:             url("/images/image-placeholder.svg") no-repeat center center;
        mask-size:        50% 50%;
      }

      .actual {
        z-index:         2;
        background-size: cover;
        background:      transparent no-repeat center center;
        border-radius:   50%;
      }
    }
  }

  &.new-holder {
    .box {
      width:      100%;
      margin-top: 1rem;
      //margin: 0;

      .content {
        .text {
          width:       100%;
          font-weight: 400;
          font-size:   12px;
          text-align:  center;
          //margin-top:    8px;
          //margin-bottom: 16px;
        }

        .add {
          position:         absolute;
          left:             calc(50% - 10px);
          background-color: $synchron-blue-dark;
          color:            $synchron-white;
          width:            20px;
          height:           20px;
          font-size:        20px;
          line-height:      20px;
          text-align:       center;
          border-radius:    5px;
          margin-top:       6px;
        }
      }
    }
  }
}
